const canvas = document.getElementById("canvas");
const context = canvas.getContext("2d");

const offsetX = 10;
const offsetY = 50;
const cellSize = 30;
const queueX = 10
const queueY = 10;
const outX = 10
const outY = 50 + 30*11 + 10;

const selectionHue = 195;

const getState = () => {
    return mode === "edit" ? initialState : playState;
}

const draw = () => {
    context.save();
    context.clearRect(0, 0, canvas.width, canvas.height);
    drawSelection1();
    drawBox(getState().box);
    drawSelection2();
    context.restore();
}

const drawSelection1 = () => {
    if (mode !== "edit") {
        return;
    }
    const [i, j] = editState.activeCell;
    const [w, h] = editState.selectionSize;
    const leftX = Math.min(i, i+w);
    const topY = Math.min(j, j+h);
    const width = 1+Math.max(i, i+w)-leftX;
    const height = 1+Math.max(j, j+h)-topY;
    context.save();
    context.fillStyle = `hsl(${selectionHue}, 90%, 90%)`;
    context.fillRect(offsetX + cellSize*leftX, offsetY + cellSize*topY, cellSize*width, cellSize*height);
    context.fillStyle = `hsl(${selectionHue}, 90%, 80%)`;
    context.fillRect(offsetX + cellSize*i, offsetY + cellSize*j, cellSize, cellSize);
    context.restore();
}

const drawSelection2 = () => {
    if (mode !== "edit") {
        return;
    }
    const [i, j] = editState.activeCell;
    const [w, h] = editState.selectionSize;
    const leftX = Math.min(i, i+w);
    const topY = Math.min(j, j+h);
    const width = 1+Math.max(i, i+w)-leftX;
    const height = 1+Math.max(j, j+h)-topY;
    context.save();
    context.strokeStyle = `hsl(${selectionHue}, 70%, 40%)`;
    context.lineWidth = "2";
    context.strokeRect(offsetX + cellSize*leftX, offsetY + cellSize*topY, cellSize*width, cellSize*height);
    context.restore();
}

const drawBox = (box) => {
    box.grid.forEach((row, rowIndex) => {
        row.forEach((cell, cellIndex) => {
            drawCell(cellIndex, rowIndex, cell, box);
        })
    });
    if (box.queue) {
        box.queue.forEach((marbleType, i) => {
            const x = queueX + cellSize*i;
            const y = queueY;
            const w = cellSize;
            const h = cellSize;
            drawMarble(marbleType, x, y, w, h);
        });
    }
    if (box.out) {
        box.out.forEach((marbleType, i) => {
            const x = outX + cellSize*i;
            const y = outY;
            const w = cellSize;
            const h = cellSize;
            drawMarble(marbleType, x, y, w, h);
        });
    }
    if (box.marble.type && box.marble.pos) {
        const [i, j] = box.marble.pos;
        const x = offsetX + cellSize*i;
        const y = offsetY + cellSize*j;
        const w = cellSize;
        const h = cellSize;
        drawMarble(box.marble.type, x, y, w, h);
    }
}

const drawMarble = (marbleType, x, y, w, h) => {
    const p = 6;
    context.save();
    context.beginPath();
    context.fillStyle = marbleType === "L" ? "hsla(110, 90%, 40%, 75%)" : "hsla(350, 90%, 45%, 75%)";
    context.arc(x+w/2, y+h/2, Math.min(w/2, h/2) - p, 0, 2*Math.PI);
    context.fill();
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillStyle = "white";
    context.fillText(marbleType, x+w/2, y+h/2);
    context.restore();
}

const drawCell = (i, j, cell) => {
    context.save();
    const cellLeftX = offsetX + cellSize*i;
    const cellTopY = offsetY + cellSize*j;
    context.strokeStyle = `hsl(${selectionHue}, 10%, 80%)`;
    if (isSelected(cell.pos)) {
        context.strokeStyle = `hsl(${selectionHue}, 60%, 65%)`;
    }
    context.strokeRect(cellLeftX, cellTopY, cellSize, cellSize);
    context.restore();
    drawSymbol(cell.value, cellLeftX, cellTopY, cellSize, cellSize);
}

const drawSymbol = (symbol, x, y, w, h) => {
    context.save();
    if (["","\\","/",">","<","L","R","?",".","#","&","v"].includes(symbol)) {
        context.beginPath();
        let p = 6;
        let q = 6;
        if (symbol === "v") {
            context.moveTo(x+p, y+h/3);
            context.lineTo(x+w/2, y+h/3+h/2-p);
            context.lineTo(x+w-p, y+h/3);
        }
        if (symbol === ">") {
            context.moveTo(x+p, y+h/2);
            context.lineTo(x+w-p, y+h/2);
            context.moveTo(x+w-p-q, y+h/2-q);
            context.lineTo(x+w-p, y+h/2);
            context.lineTo(x+w-p-q, y+h/2+q);
        }
        if (symbol === "<") {
            context.moveTo(x+w-p, y+h/2);
            context.lineTo(x+p, y+h/2);
            context.moveTo(x+p+q, y+h/2-q);
            context.lineTo(x+p, y+h/2);
            context.lineTo(x+p+q, y+h/2+q);
        }
        if (symbol === "?") {
            context.moveTo(x+w/2, y+p);
            context.lineTo(x+p, y+h/2);
            context.lineTo(x+w/2, y+h-p);
            context.lineTo(x+w-p, y+h/2);
            context.lineTo(x+w/2, y+p);
        }
        if (symbol === ".") {
            p = 8;
            context.moveTo(x+p, y+p);
            context.lineTo(x+w-p, y+p);
            context.lineTo(x+w-p, y+h-p);
            context.lineTo(x+p, y+h-p);
            context.lineTo(x+p, y+p);
        }
        if (symbol === "/") {
            context.moveTo(x+w-p, y+h/2-q);
            context.lineTo(x+w-p, y+h/2);
            context.lineTo(x+p, y+h/2);
            context.moveTo(x+p+q, y+h/2-q);
            context.lineTo(x+p, y+h/2);
            context.lineTo(x+p+q, y+h/2+q);
        }
        if (symbol === "\\") {
            context.moveTo(x+p, y+h/2-q);
            context.lineTo(x+p, y+h/2);
            context.lineTo(x+w-p, y+h/2);
            context.moveTo(x+w-p-q, y+h/2-q);
            context.lineTo(x+w-p, y+h/2);
            context.lineTo(x+w-p-q, y+h/2+q);
        }
        if (symbol === "L" || symbol === "R") {
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.fillText(symbol, x+w/2, y+h/2);
            context.arc(x+w/2, y+h/2, Math.min(w/2, h/2) - p, 0, 2*Math.PI);
        }
        if (symbol === "#") {
            // Black Box
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.fillText("#", x+w/2, y+h/2);
        }
        if (symbol === "&") {
            // Infinity
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.fillText("∞", x+w/2, y+h/2);
        }
        context.stroke();
    } else {
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillText("X", x+w/2, y+h/2);
    }
    context.restore();
}

draw();
